* {
  outline: none;
  // -ms-overflow-style: none !important;
  // /* IE and Edge */
  // scrollbar-width: none !important;

  // /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }
}

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

div,
span,
p {
  caret-color: transparent;
  line-height: 150%;
}

body {
  margin: 0;
  box-sizing: border-box;
  background-image: url(./assets/images/bg.svg);
  background-size: cover;
}

body::-webkit-scrollbar {
  width: 4px;
  background: #000000;
}

body::-webkit-scrollbar-track {
  background: 0 0;
}

body::-webkit-scrollbar-thumb {
  background: #14d1b2;
  border-radius: 16px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #14d1b2;
}

#root {
  position: relative;
}

.app {
  transition: all ease 0.33ms;
  font-size: 12px;
  font-family: "Gilroy";
  color: #ffffff;
}

.app-container {
  position: relative;
  overflow: hidden;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-42 {
  margin-top: 42px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-36 {
  font-size: 36px;
}

.font-64 {
  font-size: 64px;
}

.color1 {
  color: #ffffff;
}

.color2 {
  color: rgba(255, 255, 255, 0.5);
}

.color3 {
  color: #eef2f9;
}

.color4 {
  color: rgba(238, 242, 249, 0.5);
}

.color5 {
  color: #14d1b2;
}

.font-weight-bolder {
  // font-weight: bold;
  // font-variation-settings: "wght" 700;
  font-family: "Gilroy-ExtraBold";
}

.font-weight-b {
  font-family: "Gilroy-Bold";
}

.font-weight-5 {
  font-family: "Gilroy-Medium";
}

.font-weight-4 {
  font-family: "Gilroy";
}

.font-weight-3 {
  font-family: "Gilroy-Light";
}

.border-radius-4 {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
}
