.header-view {
	padding: 30px 0;

	.logo {
		margin-left: 32px;
	}

	.navList {

		.navItem {
			&:not(:first-child) {
				margin-left: 48px;
			}
		}
	}

	.launchBtn {
		margin-left: 48px;
		margin-right: 32px;
	}
}

@media only screen and (max-width: 680px) {
	.header-view {
		padding: 16px 0;
		.logo {
				margin-left: 16px;
			}
		
			.launchBtn {
				margin-left: 0;
				margin-right: 12px;
			}
	}
}

.drawer-view {
	width: 100%;
	height: 100vh;
	background: #0D022F;

	.linkList,
	.close {
		margin-right: 40px;
	}
}